<template>
  <div style="">
    <div class="appBar">

      <img @click="routeBack('/', false)"
           style="height: 18px; width: 18px; margin-left: 18px;"
           src="https://gcdn.bionluk.com/site/icon/tansel_mobile_black_back.svg">

      <p class="category-header-title">{{title}}</p>
      <div style="height: 18px; width: 18px;"></div>
    </div>
    <div style="margin: 0 auto; padding-top: 50px;"></div>

    <div class="explore-marketplace-gig-container" :style="index%2 ===0 ? 'background-color: #ffffff;' : 'background-color: #f4f5f7;'"
         v-for="(gigList,index) in gigLists">
      <h1 style="margin-top: 20px; margin-bottom: 16px; font-size: 26px;">{{gigList.title}}</h1>
      <div v-html="gigList.description" style="font-size:15px; font-weight: 400; color: #2d3640; opacity: 0.8;"></div>
      <div class="profile-gigs-container">
        <div class="profile-gig-body" v-for="(gig,index) in gigList.gigs" :style="index">

          <div style="z-index: 0; position: relative;" v-if="gig.portfolios.length">

            <router-link :to="gig.slug">
              <img v-if="gig.portfolios[0].fileType === 'video' || gig.portfolios[0].fileType === 'audio'"
                   style="margin-top: 7px; margin-left: 2px; cursor: pointer; position: absolute; object-fit: contain;width: 48px; height: 36px; filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 3px 10px)"
                   src="https://gcdn.bionluk.com/site/icon/icon_video_white.svg">
            </router-link>

            <router-link :to="gig.slug" style="">
              <img width="240" height="148" style="border-bottom: 1px solid #e8e8e8;;cursor: pointer;" :src="gig.portfolios[0].image_url_small"/>
            </router-link>
          </div>
          <div
            style="padding: 8px 15px 0px 15px; height: 38px; overflow: hidden;">
            <router-link style="text-decoration: none;" :to="gig.slug">
              <p style="font-size: 16px; font-weight: 500; color: #4b4f52;">
                Ben {{ gig.title }}</p></router-link>
          </div>
          <div class="profile-gig-userinfo">
            <div style="text-decoration: none; float: left; display: flex; align-items: center;">
              <img :src="gig.sellerAvatarURL" style="width: 33px; height: 33px; border-radius: 50%;"/>
              <p style="font-size: 12px; float: right; color: #8b95a1; margin-left: 7px; ">{{gig.sellerUsername}}</p>
            </div>
            <div v-if="gig.commentCount > 0" style="font-size: 14px; display: flex; flex-direction: row; align-items: center;">
              <img src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg" style="width: 14px; height: 14px; object-fit: contain; "/>
              <p style="color: #ffb700; margin-left: 3px;">{{gig.commentRating}}</p>
              <p style="color: #8b95a1; margin-left: 3px;">({{gig.commentCount}})</p>
            </div>
          </div>

          <div style="height: 1px; background-color: #dfe4ec; margin-top: 12px; margin-left: 16px; margin-right: 16px;"></div>

          <div style="height: 22px; padding: 12px 15px; display: flex; justify-content: space-between; align-items: center;">

            <div @click="updateLikeStatusForGig(gig)" style="cursor: pointer; flex: 1; height: 16px;">
              <transition name="bounce">
                <img style="height: 16px;" :src="$root.CDN_SITE +  gig.likeStatus.actionIcon" :key="gig.likeStatus.actionIcon">
              </transition>
            </div>
            <p style=" color: #2d3640;">
                    <span style="	font-size: 12px; text-align: right; color: #aeb5b5;">
                    {{gig.priceHelpText}} </span>
              <span style="font-size: 18px;font-weight: 500;text-align: right; color: #2d3640; "> {{gig.priceText}} </span>
            </p>
          </div>
        </div>
      </div>
      <button class="super-button get-started-button" v-if="!user.uuid" @click="$router.push('/register')">Hemen başla</button>
    </div>
    <div class="hiddendiv"></div>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-marketplace-goLanding-v1_mobile",
    components: {
    },
    data() {
      return {
        gigLists: null,
        title:null
      }
    },

    methods: {
      getGoPage() {
        let slug = this.$store.state.routerParams[0];
        this.api.content.getGoPage(slug)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.gigLists = result.data.gig_lists;
              this.title = result.data.title;
              this.Helper.trackEvents.pageView(this.Helper.goLandingPageProps(result.data.id, result.data.slug, result.data.title));
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      updateLikeStatusForGig(receivedGig) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, receivedGig.likeStatus.action, receivedGig.uuid,null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
            } else {
              this.$router.push(result.data.redirect_url);
              this.revertLikeStatus(receivedGig);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(receivedGig);
          });
        this.reverseLikeStatus(receivedGig);
      },

      revertLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        }
      },

      reverseLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        }
      },

    },

    watch: {

    },

    created() {
      this.getGoPage();
    }
  }

</script>

<style scoped lang="scss">
  .appBar {
    z-index: 99;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .explore-marketplace-gig-container {
    padding: 20px 15px 20px;
    min-width: calc(100vw - 30px);
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .explore-marketplace-gig-container h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #2d3640;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .profile-gigs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100vw - 30px);
    overflow-y: scroll;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .profile-gig-body {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }

    display: inline-block;
    width: 240px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
    text-align: left;
  }

  .profile-gig-userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }

  .get-started-button {
    border-radius: 2px;
    background-image: linear-gradient(45deg, rgb(230, 50, 98), rgb(253, 64, 86));
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding-bottom: 3px;
    width: 220px;
    margin: 10px auto 10px;
    height: 50px;
  }
  .get-started-button:hover {


    background-image: linear-gradient(45deg, #ee2344, #ee2344);

  }

</style>
